import React from "react"
import "./reset.css"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLinkedin,
  faGithubAlt
} from "@fortawesome/free-brands-svg-icons"

import {
  faEnvelope
} from "@fortawesome/free-solid-svg-icons"


export default function Home() {
  const colors = {
    backgroundPrimary: '#f4faff',
    textPrimary: '#0C080B',
    headerTextSecondary: '#A4A3AA'
  }


  const Header = styled.div`
  padding: 2rem;
  text-align: center;
  background-color:${colors.backgroundPrimary};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,.125);
  `

  const NameHeader = styled.h1`
    font-size: 3rem;
    font-family: AlfaSlabOne;
    padding: 0 0 12px 0
  `

  const DescriptionHeader = styled.h2`
    font-size: 1.3rem;
    color: #aaa;
    font-weight: 100;
    font-family: AlfaSlabOne, open sans,sans-serif;
  `

  const Main = styled.main`
    padding: 2rem 2em;
    display: block;
    max-width: 50em;
    margin: 0 auto;
    @media(min-width: 480px) {
        padding: 2rem 3em;
        display: flex;
    }
  `

  const FirstColumn = styled.div`
    padding: 1em;
    flex: 4 1;
  `
  const SecondColumn = styled.div`
    padding: 1em;
    flex: 1 1;
  `

  const Link = styled.a`
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    text-decoration: none;
    color: ${colors.textPrimary};
    transition: color 0.1s linear;
    :hover {
      color: #8fb8ff;
    }
  `

  const SocialLabel = styled.span`
    font-size: 1rem;
    margin: 0 0 0 0.5rem;
    font-family: open sans,sans-serif;
  `

  const AboutText = styled.p`
    font-size: 1rem;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    font-family: Lato, open sans,sans-serif;
  `

  const HeaderAbout = styled.h3`
    font-size: 1.5rem;
    color: ${colors.textPrimary};
    font-weight: 700;
    margin-bottom: 1.5rem;
    font-family: open sans,sans-serif;
  `

  const Footer = styled.footer`
    font-family: Lato, open sans, sans-serif;
    text-align: center;
    position: absolute;
    height: 1rem;
    bottom: 0;
    padding: 12px 0;
    background-color: ${colors.backgroundPrimary};
    width: 100%;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0,0,0,.125);
  `

  const PageContainer = styled.div`
    position: relative;
    min-height: 100vh;
`

  return <PageContainer>
    <Header>
      <div>
        <a>
          <img/>
        </a>
        <NameHeader>Mikołaj Sikorski</NameHeader>
        <DescriptionHeader>Java script developer</DescriptionHeader>
      </div>
    </Header>
    <Main>
      <FirstColumn>
        <HeaderAbout>
          About me
        </HeaderAbout>
        <AboutText>
          Welcome, I'm a web developer with more than 5 years of
          professional experience. I had a chance to work on many projects and get familiar with many frontends (not only) technologies. I was working on apps based on Angular or React. The project I most proud of is a p2p communicator based on webRTC. However, I worked on many other interesting projects such: as a big saas application where my team had to handle many live times updates, in the other app I had to handle animation and display content in many fancy ways using canvas. In all my projects, I was using many different bundlers such as webpack, gulp, grunt, rollup. In addition as a frontend developer, I had to work with CSS and HTML so if you are curious how I handle styles, here is the answer -  my favorite solution for styles in apps is SASS with BEM or styled-component.
          Also as my side activity, I'm trying to make a game using the Unity engine. If you have some interesting projects for me you can reach me using the links.
        </AboutText>
      </FirstColumn>
      <SecondColumn>
        <HeaderAbout>
          Contact
        </HeaderAbout>
        <div>
          <Link target="_blank" href="https://www.linkedin.com/in/miko%C5%82aj-sikorski-4aa2a79b/">
            <FontAwesomeIcon icon={faLinkedin} size={"2x"}/>
            <SocialLabel>LinkedIn</SocialLabel>
          </Link>
          <Link target="_blank" href="https://github.com/MSkipper">
            <FontAwesomeIcon icon={faGithubAlt} size={"2x"}/>
            <SocialLabel>Git</SocialLabel>
          </Link>
          <Link target="_blank" href="mailto:mik.sikorski@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} size={"2x"}/>
            <SocialLabel>Email</SocialLabel>
          </Link>
        </div>
      </SecondColumn>
    </Main>
    <Footer>
      © 2020
    </Footer>
  </PageContainer>
}
